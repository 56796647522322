import React, { useContext, useRef } from "react";
import NextLink from "next/link";
import PropTypes from "prop-types";

import { ContentRow } from "components/content-row/content-row";
import { Image } from "components/image/image";
import { ImageShape } from "components/image/image.shape";
import { Ticker } from "components/ticker/ticker";

import { DeviceOutputContext } from "context/device-output.context";

import { StatusEnum } from "enumeration/status.enum";

import { Link } from "utils/link";
import { Markdown } from "utils/markdown";

import Styles from "./hero.styles";

export const Hero = ({
    status,
    global: { donateLinkUrl, donateLinkTitle },
    categories,
    category: currentCategory,
    title,
    titleWidth,
    image,
    imagePosition,
    ticker,
}) => {
    const { isApp } = useContext(DeviceOutputContext);
    const menuRowRef = useRef(null);
    const menuBarRef = useRef(null);

    if (status === StatusEnum.DRAFT) return null;

    categories.sort((a, b) => {
        if (a.order > b.order) {
            return -1;
        }
        if (b.order > a.order) {
            return 1;
        }
        return 0;
    });

    return (
        <>
            {ticker && isApp === true && <Ticker {...ticker} />}

            <ContentRow>
                <Styles.Row>
                    <Styles.Title titleWidth={titleWidth}>
                        <Markdown>{title}</Markdown>
                    </Styles.Title>
                </Styles.Row>

                <Styles.ImageWrapper objectPosition={imagePosition}>
                    {image && image.url && <Image image={image} />}
                </Styles.ImageWrapper>
            </ContentRow>

            {ticker && isApp === false && <Ticker {...ticker} />}

            {isApp === true && (
                <Styles.MenuRow ref={menuRowRef}>
                    <Styles.MenuContainer>
                        <Styles.MenuBar ref={menuBarRef}>
                            {categories.map((category) => {
                                if (!category.inNavigation) {
                                    return;
                                }

                                // eslint-disable-next-line consistent-return
                                return (
                                    <Styles.MenuItem
                                        key={category.id}
                                        active={currentCategory && category.id === currentCategory.id}
                                    >
                                        <NextLink as={`/category/${category.slug}`} href="/category/[slug]">
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                            <a>
                                                <Markdown>{category.displayName}</Markdown>
                                            </a>
                                        </NextLink>
                                    </Styles.MenuItem>
                                );
                            })}

                            {donateLinkUrl && donateLinkTitle && (
                                <Styles.MenuItem>
                                    <Link href={donateLinkUrl} target="_blank">
                                        <p>{donateLinkTitle}</p>
                                    </Link>
                                </Styles.MenuItem>
                            )}
                        </Styles.MenuBar>
                    </Styles.MenuContainer>
                </Styles.MenuRow>
            )}
        </>
    );
};

Hero.defaultProps = {
    status: null,
    category: null,
    titleWidth: "60%",
    imagePosition: "center center",
    ticker: null,
};

Hero.propTypes = {
    status: PropTypes.string,
    global: PropTypes.shape({
        donateLinkUrl: PropTypes.string,
        donateLinkTitle: PropTypes.string,
    }).isRequired,
    categories: PropTypes.any.isRequired,
    category: PropTypes.any,
    title: PropTypes.string.isRequired,
    titleWidth: PropTypes.string,
    image: PropTypes.shape(ImageShape).isRequired,
    imagePosition: PropTypes.string,
    ticker: PropTypes.shape({
        text: PropTypes.string.isRequired,
        backgroundColor: PropTypes.string,
        url: PropTypes.string,
    }),
};

export default Hero;
