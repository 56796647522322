import React from "react";
import PropTypes from "prop-types";

import { Content } from "components/content";
import { Layout } from "components/layout";
import { Seo } from "components/seo";

import { fetchAPI } from "lib/api";

import { Hero } from "sections/hero/hero";

import Styles from "styles/shared.styles";

const Home = ({ global, homepage, categories, articles }) => {
    return (
        <Layout categories={categories} global={global}>
            <Seo seo={homepage.seo} />

            <Styles.Content>
                <Hero global={global} categories={categories} {...homepage.hero} />

                <Content content={homepage.content} articles={articles} />
            </Styles.Content>
        </Layout>
    );
};

export async function getStaticProps() {
    const [articles, categories, homepage, global] = await Promise.all([
        fetchAPI("/articles?status=published"),
        fetchAPI("/categories?status=published"),
        fetchAPI("/homepage"),
        fetchAPI("/global"),
    ]);

    return {
        props: {
            global,
            homepage,
            categories,
            articles,
        },
        revalidate: 1,
    };
}

Home.propTypes = {
    global: PropTypes.any.isRequired,
    homepage: PropTypes.any.isRequired,
    categories: PropTypes.any.isRequired,
    articles: PropTypes.any.isRequired,
};

export default Home;
