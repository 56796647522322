import styled, { keyframes } from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Banner = styled.div`
    background-color: ${(props) => props.backgroundColor};
`;

const move = keyframes`
    0% {
        left: 0;
    }
    25% {
        left: -650px;
    }
    50% {
        left: -1300px;
    }
    75% {
        left: -1950px;
    }
    100% {
        left: -2600px;
    }
`;

const Text = styled.div`
    cursor: pointer;
    font-size: 30px;
    line-height: 24px;
    margin: 0 auto;
    overflow: hidden;
    padding: 6px 0;
    width: 100%;

    -ms-overflow-style: none;

    ::-webkit-scrollbar {
        display: none;
    }

    p {
        font-size: 24px;
        line-height: 28px;
        margin: 0;

        a {
            text-decoration-line: underline;
            text-decoration-color: ${Colors.BLUE_005EA8};
        }
    }

    ${minMedia.desktop`
         padding: 10px 0;

         p {
            font-size: 30px;
            line-height: 36px;
         }
    `};
`;

const Animation = styled.div`
    width: 4000px;
`;

const Entry = styled.span`
    display: inline-block;
    animation: ${move} 40s linear infinite ${(props) => (props.isPaused && "paused") || "running"};
    position: relative;
    width: 1300px;
`;

export default {
    Banner,
    Text,
    Animation,
    Entry,
};
