import React, { useState } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";

import { EnumHelper } from "helper/enum-helper";
import { StringHelper } from "helper/string-helper";

import { Markdown } from "utils/markdown";

import Styles from "./ticker.styles";

export const Ticker = ({ text, backgroundColor, url }) => {
    const [isPaused, setIsPaused] = useState(false);
    const router = useRouter();

    if (!text) {
        return null;
    }

    const handleClick = (e) => {
        if (StringHelper.isNullOrEmpty(url)) return;

        e.preventDefault();
        router.push(url);
    };

    return (
        <Styles.Banner backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
            <Styles.Text
                onClick={handleClick}
                onMouseEnter={() => setIsPaused(true)}
                onMouseLeave={() => setIsPaused(false)}
            >
                <Styles.Animation>
                    <Styles.Entry isPaused={isPaused}>
                        <Markdown>{text}</Markdown>
                    </Styles.Entry>

                    <Styles.Entry isPaused={isPaused}>
                        <Markdown>{text}</Markdown>
                    </Styles.Entry>

                    <Styles.Entry isPaused={isPaused}>
                        <Markdown>{text}</Markdown>
                    </Styles.Entry>
                </Styles.Animation>
            </Styles.Text>
        </Styles.Banner>
    );
};

Ticker.defaultProps = {
    url: null,
};

Ticker.propTypes = {
    text: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    url: PropTypes.string,
};

export default Ticker;
