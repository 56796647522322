import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Row = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 210px;
    width: 100%;

    ${minMedia.desktop`
        height: 550px;
    `};
`;

const Title = styled.div`
    background-color: transparent;
    max-width: ${(props) => props.titleWidth ?? "60%"};
    padding: 12px 15px;
    z-index: 10;

    h1 {
        background-color: ${Colors.BLUE_063773};
        box-decoration-break: clone;
        box-shadow: -8px 0 0 8px ${Colors.BLUE_063773}, 8px 0 0 8px ${Colors.BLUE_063773},
            0 0 0 8px ${Colors.BLUE_063773};
        color: ${Colors.WHITE_FFFFFF} !important;
        display: inline;
        font-size: 24px !important;
        font-weight: ${(props) => props.theme.fontWeight.bold} !important;
        hyphens: auto;
        line-height: 59px;
        margin: 0;
        overflow: hidden;
        padding: 0;
    }

    ${minMedia.desktop`
        padding: 20px;

        h1 {
            box-shadow: -12px 0 0 10px ${Colors.BLUE_063773}, 12px 0 0 10px ${Colors.BLUE_063773}, 0 0 0 10px ${Colors.BLUE_063773};
            font-size: 60px !important;
            line-height: 122px;
        }
    `};
`;

const ImageWrapper = styled.div`
    bottom: 0;
    height: 210px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    ${minMedia.desktop`
        height: 550px;
    `};

    img {
        height: 100%;
        object-fit: cover;
        object-position: ${(props) => props.objectPosition || "center center"};
        width: 100%;
    }
`;

const MenuRow = styled.div`
    position: relative;
    height: 65px;

    ${minMedia.desktop`
        height: 73px;
    `};
`;

const MenuContainer = styled.div`
    box-sizing: content-box;
    display: block;
    height: 50px;
    left: 0;
    margin: 0 auto;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 10px 0 0;
    text-align: center;
    top: 0;
    -webkit-overflow-scrolling: touch;
    width: 100%;

    &::-webkit-scrollbar {
        display: none;
    }

    ${minMedia.desktop`
        padding: 25px 0 0;
    `};
`;

const MenuBar = styled.div`
    display: inline-block;
    padding: 0 10px;
    white-space: nowrap;

    ${minMedia.desktop`
        padding: 0 30px;
    `};
`;

const MenuItem = styled.li`
    background-color: ${(props) => (props.active ? Colors.WHITE_FFFFFF : Colors.BLUE_0271C2)};
    border: 1px solid ${Colors.BLUE_0271C2};
    border-radius: 20px;
    box-sizing: border-box;
    display: inline-block;
    margin: 0 10px 0 0;
    padding: 0 20px;
    text-decoration: none;

    &:hover {
        opacity: 0.8;
    }

    &:last-child {
        margin-right: 0;
    }

    a {
        cursor: pointer;
        display: block;
        text-decoration: none;

        p {
            color: ${(props) => (props.active ? Colors.BLUE_0271C2 : Colors.WHITE_FFFFFF)};
            font-size: 17px;
            font-weight: ${(props) => props.theme.fontWeight.regular};
            line-height: 35px;
            margin: 0;
            padding: 0;
        }
    }

    ${minMedia.desktop`
        border-radius: 25px;
        padding: 0 25px;

        a p {
            font-size: 24px;
            line-height: 48px;
        }
    `};
`;

export default {
    Row,
    Title,
    ImageWrapper,
    MenuRow,
    MenuContainer,
    MenuBar,
    MenuItem,
};
